@import url("https://fonts.googleapis.com/css2?family=Jura:wght@700&family=Montserrat:wght@700&family=Questrial&display=swap");

:root {
	--ticket-bgColor: #f3f1c9;
}

.ticket-wrapper {
	display: grid;
	grid-template-columns: repeat(12, 50px);
	grid-template-rows: repeat(9, 25px);
	background-color: white;
	border-top: 2px solid black;
	border-left: 2px solid black;
	font-family: "Questrial", sans-serif;
}

.ticket-element {
	background-color: var(--ticket-bgColor);
	border-bottom: 2px solid black;
	border-right: 2px solid black;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.ticket-element p {
	margin: 0;
}

.ticket-element .label {
	text-transform: uppercase;
	font-size: 12px;
	padding-left: 10px;
}

.ticket-element .data {
	font-size: 15px;
	padding-left: 10px;
	font-family: "Jura", sans-serif;
}

.ticket-element.ticket-title {
	grid-column: 1/13;
	grid-row: 1/4;
	font-size: 40px;
	text-transform: uppercase;
	font-weight: bold;
	background-color: black;
	color: var(--ticket-bgColor);
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	padding: 0 20px;
	font-family: "Montserrat", sans-serif;
}

.ticket-eventName {
	grid-column: 1/10;
	grid-row: 4/6;
}

.ticket-studentName {
	grid-column: 1/6;
	grid-row: 6/8;
}

.ticket-studentID {
	grid-column: 6/10;
	grid-row: 6/8;
}

.ticket-seatNumber {
	grid-column: 1/4;
	grid-row: 8/10;
}

.ticket-studentCourse {
	grid-column: 4/7;
	grid-row: 8/10;
}

.ticket-eventDate {
	grid-column: 7/10;
	grid-row: 8/10;
}

.ticket-qrCode {
	grid-row: 4/10;
	grid-column: 10/13;
	background-color: tomato;
    display: flex;
    align-items: center;
    justify-content: center;
}