.seat {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: 1px solid gray;
}

.seat .seat-no {
	font-size: 10px;
}

.seat-design {
	width: 25px;
	height: 25px;
	border-radius: 5px;
}

.seat.occupied {
	color: rgb(182, 182, 182);
	background-color: rgb(182, 182, 182);
}

.seat.selected {
	color: white;
	border: none;
	background-color: #543864;
}
