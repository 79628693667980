.layout-booking {
	min-height: 100vh;
}

.header-booking {
	display: flex;
	align-items: center;
	justify-content: center;
}

.header-sticky {
	position: sticky;
	top: 0;
	z-index: 1;
}

.booking-navbar-heading {
	color: white;
	font-size: 22px;
	font-weight: 500;
	padding: 0 20px;
	text-transform: uppercase;
}

.content-booking {
	height: 100%;
}

.footer {
	height: 60px;
	width: 100%;
	position: fixed;
	bottom: 0px;
	background-color: rgb(255, 111, 0);
	display: flex;
	align-items: center;
	justify-content: center;
}

#booking-page {
	width: 100%;
	min-height: 100vh;
	position: relative;
	margin-bottom: 100px;
	user-select: none;
}

#seating-layout {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-width: max-content;
}

.seating-header {
	border: 2px solid grey;
	background-color: rgb(154, 154, 154);
	text-align: center;
	padding: 20px;
	margin: 30px 0;
	font-weight: bold;
	border-radius: 5px;
}

.balcony .seating-section {
	display: grid;
	grid-template-columns: repeat(34, 1fr);
	grid-template-rows: repeat(14, 20px);
	column-gap: 0.8rem;
	row-gap: 0.8rem;
}

.balcony .seating-section .top-walkway {
	grid-column: 13/23;
}

.balcony .seating-section .walkway {
	grid-column: 17/19;
}

.row-name {
	grid-column: 1/3;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	/* color: white; */
}

.balcony .row-end-name {
	grid-column: 33/35;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
}

.backside .seating-section {
	display: grid;
	/* 2 extra columns fro walkway */
	grid-template-columns: repeat(30, 1fr);
	grid-template-rows: repeat(14, 20px);
	column-gap: 0.8rem;
	row-gap: 0.8rem;
}

.backside .seating-section .walkway {
	grid-column: 15/17;
}

.backside .row-end-name {
	grid-column: 29/31;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
}

.front .seating-section {
	display: grid;
	/* 2 extra columns fro walkway */
	grid-template-columns: repeat(34, 1fr);
	grid-template-rows: repeat(12, 20px);
	column-gap: 0.8rem;
	row-gap: 0.8rem;
}

.front .seating-section .walkway {
	grid-column: 17/19;
}

.front .row-end-name {
	grid-column: 33/35;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
}

@media only screen and (max-width: 1100px) {
	.layout-booking {
		min-width: 100%;
		width: max-content;
	}

	.header-booking{
		height: 200px;
		flex-direction: column;
	}

	.booking-navbar-heading{
		font-size: 60px;
	}

	.header-booking .ant-btn{
		height: 60px;
		font-size: 30px;
		margin-top: 25px;
	}
}
