.overlay {
	height: 100%;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.685);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.invertis {
	font-size: 5rem;
	font-weight: bold;
	color: white;
	text-transform: uppercase;
	opacity: 100%;
	margin: 0;
	text-align: center;
}

.subtitle {
	font-size: 50px;
	font-weight: bold;
	color: white;
	text-transform: uppercase;
	opacity: 90%;
	margin: 0;
}

.subtitle-small{
	font-size: 20px;
	color: white;
	text-transform: uppercase;
	opacity: 90%;
	margin: 0;
}

/* //////////////////////////////////////////////////////// */

.ant-layout-header{
	padding: 0 !important;
}

.layout-home {
	height: 100vh;
	background-image: url("../../public/home-bg.JPG");
	background-position: bottom;
	background-repeat: no-repeat;
	background-size: cover;
}

.content-home {
	height: 100%;
}

.content-wrapper {
	height: 100%;
}

.login-btn-home {
	margin-top: 30px;
}

@media only screen and (max-width: 640px) {
	.invertis {
		font-size: 2rem;
	}

	.subtitle {
		font-size: 1.5rem;
		margin-top: 0.5rem;
	}
}
